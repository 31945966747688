import React, { Component } from 'react';
import logo from '../images/logo-dark.svg';
import { Container, Navbar, Nav } from 'react-bootstrap';

class TopNav extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <Navbar bg="white" expand="lg" className="border-bottom">
        <Container >
          <Navbar.Brand href="#home">
            <img src={logo} height={20} className="mr-2" alt="Snappr logo" />
            Eventbrite Event Finder
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* <Nav className="mr-auto">
            <Nav.Link href="#">Home</Nav.Link>
            <Nav.Link href="#">Link</Nav.Link>
          </Nav> */}
            <Nav className="ml-auto">
              <Nav.Link href="https://www.snappr.co">Home</Nav.Link>
              {/* <Nav.Link href="#">Link</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

}

export default TopNav;