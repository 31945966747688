import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import moment from 'moment';
import { Container } from 'react-bootstrap';

import TopNav from './components/TopNav';
import Search from './components/Search';
import SearchResults from './components/SearchResults';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      error: 'none',
      searching: false,
      startDate: '',
      endDate: '',
      search: {
        keyword: 'party',
        sortBy: '',
        locationAddress: '',
        locationWithin: '',
        categories: '', // comma delimited string of category IDs
        subcategories: '', // comma delimited string of subcategory IDs
        formats: '',
        startDateRangeStart: '',
        startDateRangeEnd: ''
      }
    }
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.updateResults = this.updateResults.bind(this);
  }

  updateResults() {
    this.setState({ searching: true });
    let params = this.state.search;
    axios.get('/api/events', { params: params })
      .then(response => {
        console.log(JSON.parse(response.data));
        this.setState({
          events: JSON.parse(response.data).events,
          searching: false
        });
      })
      .catch(error => {
        this.setState({
          error: error.message,
          searching: false
        });
      });
  };

  componentDidMount() {
    this.updateResults();
  }

  onSearchChange(event) {
    let clickedVariable = event.target.dataset.variable;
    let clickedValue = event.target.value;
    this.setState(prevState => {
      let search = { ...prevState.search };
      search[clickedVariable] = clickedValue;
      return { search };
    })
  }

  onDateChange({ startDate, endDate }) {
    this.setState({ startDate, endDate })
    this.setState(prevState => {
      let search = { ...prevState.search };
      search.startDateRangeStart = startDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss') : '';
      search.startDateRangeEnd = endDate ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss') : '';
      return { search };
    })
  };

  render() {
    return (
      <div>
        <TopNav />
        <Container className="mt-4">
          <h1 className="mb-4">Event search</h1>
          <div className="row">
            <div className="col-3">
              <Search search={this.state.search} onSearchChange={this.onSearchChange} onDateChange={this.onDateChange} updateResults={this.updateResults} startDate={this.state.startDate} endDate={this.state.endDate} />
            </div>
            <div className="col-9">
              <SearchResults events={this.state.events} searching={this.state.searching} />
            </div>
          </div>
        </Container>
      </div>
    )
  };
}

export default App;
