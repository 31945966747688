import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
// import './FilterDates.css';
import 'react-dates/lib/css/_datepicker.css';

class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="bg-white p-3" style={{borderRadius: "2px"}}>
        <h3>Search parameters</h3>
        <div className="form-group">
          <label>Keyword</label>
          <input className="form-control" data-variable="keyword" value={this.props.search.keyword} onChange={this.props.onSearchChange} />
        </div>
        <div className="form-group">
          <label>Sort by</label>
          <select className="form-control" data-variable="sortBy" value={this.props.search.sortBy} onChange={this.props.onSearchChange} >
            <option value="date">Date</option>
            <option value="distance">Distance</option>
            <option value="best">Best</option>
          </select>
        </div>
        <div className="form-group">
          <label>Address</label>
          <input className="form-control" data-variable="locationAddress" value={this.props.search.locationAddress} onChange={this.props.onSearchChange} />
        </div>
        <div className="form-group">
          <label>Radius</label>
          <input className="form-control" data-variable="locationWithin" value={this.props.search.locationWithin} onChange={this.props.onSearchChange} />
        </div>
        <div className="form-group">
          <label>Categories</label>
          <select className="form-control" data-variable="categories" value={this.props.search.categories} onChange={this.props.onSearchChange} >
            <option value="">Any category</option>
            <option value="118">Auto, Boat &amp; Air</option>
            <option value="101">Business</option>
            <option value="111">Charity &amp; Causes</option>
            <option value="113">Community</option>
            <option value="115">Family &amp; Education</option>
            <option value="106">Fashion</option>
            <option value="104">Film &amp; Media</option>
            <option value="110">Food &amp; Drink</option>
            <option value="112">Government</option>
            <option value="107">Health</option>
            <option value="119">Hobbies</option>
            <option value="116">Holiday</option>
            <option value="117">Home &amp; Lifestyle</option>
            <option value="103">Music</option>
            <option value="105">Performing &amp; Visual Arts</option>
            <option value="120">School Activities</option>
            <option value="102">Science &amp; Tech</option>
            <option value="114">Spirituality</option>
            <option value="108">Sports &amp; Fitness</option>
            <option value="109">Travel &amp; Outdoor</option>
            <option value="199">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Subcategories</label>
          <input className="form-control" data-variable="subcategories" value={this.props.search.subcategories} onChange={this.props.onSearchChange} />
        </div>
        <div className="form-group">
          <label>Formats</label>
          <select class="form-control" data-variable="formats" value={this.props.search.formats} onChange={this.props.onSearchChange} >
            <option value="">Any format</option>
            <option value="19">Appearance</option>
            <option value="17">Attraction</option>
            <option value="9">Class</option>
            <option value="1">Conference</option>
            <option value="4">Convention</option>
            <option value="3">Expo</option>
            <option value="5">Festival</option>
            <option value="8">Gala</option>
            <option value="14">Game</option>
            <option value="10">Networking</option>
            <option value="11">Party</option>
            <option value="6">Performance</option>
            <option value="15">Race</option>
            <option value="12">Rally</option>
            <option value="18">Retreat</option>
            <option value="7">Screening</option>
            <option value="2">Seminar</option>
            <option value="16">Tour</option>
            <option value="13">Tournament</option>
          </select>
        </div>
        <div className="form-group">
          <label>Date range</label>
          <DateRangePicker
            startDate={this.props.startDate} // momentPropTypes.momentObj or null
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired
            endDate={this.props.endDate} // momentPropTypes.momentObj or null
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired
            onDatesChange={this.props.onDateChange} // PropTypes.func.isRequired
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
            showClearDates={true}
            small={true}
            block={true}
            displayFormat="DD/MM/YY"
            startDatePlaceholderText="Start date"
            endDatePlaceholderText="End date"
            isOutsideRange={() => false}
          />
        </div>
        <button className="btn btn-primary" onClick={this.props.updateResults}>Update</button>
      </div>
    )
  }

}

export default Search;