import React, { Component } from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';

import Loader from './Loader';

class SearchResults extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="bg-white p-3" style={{borderRadius: "2px"}}>
        <h3>
          Results
          {this.props.searching && <Loader />}
        </h3>
        <div style={{ fontSize: "12px", height: "calc(100vh - 210px)", overflowY: "scroll" }}>
          <Table hover size="sm">
            <thead>
              <tr>
                <th>Event name</th>
                <th></th>
                <th>Category</th>
                <th>Subcategory</th>
                <th>Organizer</th>
                <th>Date</th>
                <th>Time</th>
                {/* <th>Summary</th> */}
                <th>Venue</th>
                <th>Venue capacity</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {this.props.events.map(event => {
                return <tr key={event.id}>
                  <td>{event.logo && <img width="100" src={event.logo ? event.logo.url : ""} alt={event.name.text} />}</td>
                  <td>{event.name.text}</td>
                  <td>{event.category ? event.category.name : 'N/A'}</td>
                  <td>{event.subcategory ? event.subcategory.name : 'N/A'}</td>
                  <td>{event.organizer ? event.organizer.name : 'N/A'}</td>
                  <td>{moment(event.start.local).format('D MMM YY')}</td>
                  <td>{moment(event.start.local).format('h:mm a')}</td>
                  {/* <td>{event.summary}</td> */}
                  <td>{event.venue ? event.venue.name : 'Unknown'}</td>
                  <td>{event.venue ? event.venue.capacity : 'Unknown'}</td>
                  <td><a href={event.url} target="_blank">Link</a></td>
                </tr>
              })}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }

}

export default SearchResults;