import React, { Component } from 'react';

class Loader extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="d-inline-block ml-2">
        <div className="circ_1"></div>
        <div className="circ_2"></div>
        <div className="circ_3"></div>
        <div className="circ_4"></div>
        <div className="circ_5"></div>
        <div className="circ_6"></div>
      </div>
    )
  }

}

export default Loader;